import {
  createFileRoute,
  Link,
  useNavigate,
  useRouter,
} from "@tanstack/react-router";
import dockernames from "docker-names";
import { mergeDeepRight } from "ramda";
import React from "react";
import { ComponentProps } from "react";
import { Button } from "~/@/components/ui/button";
import { Checkbox } from "~/@/components/ui/checkbox";
import { cn } from "~/@/lib/utils";
import { useCreateBusinessWallet } from "~/api/codegen/liquidityComponents";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { notify, notifyApiError } from "~/components/Toast";
import { KycForm } from "~/components/forms/kyc-form";
import {
  CardBackButton,
  CardBody,
  CardFooter,
  CardHeader,
  CardRoot,
  CardSeparator,
  CardTitle,
} from "~/components/layouts/Card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/@/components/ui/select";
import { currencies } from "~/constants/currencies";
import { query_client } from "~/contexts/react-query";
import { useBusiness, useGetBusinessId } from "~/hooks/use-business";
import { useWallets } from "~/hooks/use-wallet";

export const Route = createFileRoute("/dashboard/wallet/collections/setup")({
  component: Page,
});

function Page() {
  const navigate = useNavigate();
  const router = useRouter();
  const [state, setState] = React.useState({});
  const businessId = useGetBusinessId();
  const business = useBusiness();
  const { data: wallets } = useWallets();

  const mutation = useCreateBusinessWallet();

  const isValid = React.useMemo(() => {
    return Object.keys(state).filter((key) => state[key] === true).length > 0;
  }, [state]);

  return (
    <div className="mt-12">
      <CardRoot size="lg">
        <CardHeader className="flex justify-between gap-2">
          <Link className="flex items-center">
            <CardBackButton
              onClick={() => {
                router.history.back();
              }}
            />
          </Link>
          <CardTitle>Setup Wallet</CardTitle>
        </CardHeader>

        <CardSeparator />

        <CardBody className="py-2 lg:px-12">
          <hgroup className="mb-4">
            <h2 className="py-3 font-semibold">Available Currencies</h2>
            <p className="text-muted-foreground">
              Select the currency wallet you want to setup
            </p>
          </hgroup>
          <CardSeparator className="border border-dashed border-[#EFF2F3] bg-transparent" />
          <div className="my-6 flex items-center justify-between">
            <h2 className="font-normal text-muted-foreground">
              Select wallet to create
            </h2>
            <div className="w-[30%]">
              <Select value="cash" disabled>
                <SelectTrigger className="h-8 w-full">
                  <SelectValue
                    placeholder="Wallet Type"
                    defaultValue={"cash"}
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="cash">Cash</SelectItem>
                  <SelectItem value="crypto">Crypto</SelectItem>
                  <SelectItem value="collectibles">Collectibles</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          <ul className="flex flex-col divide-y divide-dashed border-[#DEE5E7]">
            {currencies.map((currency) => {
              const hasWallet = currency.code in wallets.CORPORATE;
              const labelId = `currency-${currency.code}`;

              return (
                <li key={currency.code}>
                  <label
                    htmlFor={labelId}
                    className={cn(
                      "flex select-none items-center gap-x-6 py-4 text-gray-800",
                      {
                        "pointer-events-none opacity-25": hasWallet,
                      },
                    )}
                  >
                    <Checkbox
                      id={labelId}
                      checked={state[currency.code]}
                      disabled={hasWallet}
                      onCheckedChange={(value) => {
                        setState((prev_state) =>
                          mergeDeepRight(prev_state, {
                            [String(currency.code)]: value,
                          }),
                        );
                      }}
                    />

                    <span className="flex flex-1 gap-x-3">
                      <FlatImage
                        src={currency.flagUrl}
                        alt={currency.code}
                        className="aspect-[12/8]"
                      />
                      <span className="text-sm font-semibold">
                        {currency.name}
                      </span>
                    </span>

                    <span className="inline-block text-sm">
                      {currency.code}
                    </span>
                  </label>
                </li>
              );
            })}
          </ul>
        </CardBody>

        <CardFooter className="mt-5 justify-center">
          <Button
            className="w-1/2"
            disabled={!isValid}
            onClick={async () => {
              try {
                await Promise.all(
                  Object.keys(state).map((code) => {
                    return mutation.mutateAsync({
                      pathParams: { businessId },
                      body: {
                        slug: `${business.slug}-${dockernames.getRandomName()}`,
                        // description: "Random description",
                        currency: code,
                        walletType: "CORPORATE",
                      },
                    });
                  }),
                );
                navigate({ to: "/dashboard/wallet/spend/" });
                notify("success", "Wallet created successfully");
                query_client.invalidateQueries({
                  predicate: (q) => q.queryKey.includes("business"),
                });
              } catch (err) {
                console.error(err, "error creating wallet");
                notifyApiError(err);
              }
            }}
          >
            <LoadingButtonContent loading={mutation.isPending}>
              Add wallets
            </LoadingButtonContent>
          </Button>
        </CardFooter>
      </CardRoot>
    </div>
  );
}

function FlatImage(props: ComponentProps<"img">) {
  return (
    <img
      {...props}
      src={props.src}
      className={cn("aspect-[16/12] w-[32px]", props.className)}
      alt={props.alt ?? "Flag image"}
    />
  );
}
