import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { formatNumber } from "~/libs/currency.helpers";
import { getDateFormat } from "~/libs/date.helpers";
import { StatusBadge, StatusDot } from "../Badges";
import { ApplicationTokenTypes } from "~/libs/types.helper";
import { truncateString } from "~/libs/string.helper";
import { notify } from "../Toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyIcon, EllipsisVertical } from "~/assets/icons";
import { LinkDropdown } from "~/routes/dashboard/wallet/spend";
import { CentralWalletUser, userBadgeStatus } from "~/libs/factories/user";
import { useSharedCurrency } from "~/contexts/currency";
import { BalanceMap } from "~/libs/factories/balance-map";
import { Badge } from "../atoms/Badge";
import { capitalize } from "@mui/material";
import { toLower } from "ramda";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/@/components/ui/dropdown-menu";
import { useNavigate } from "@tanstack/react-router";

const columns = createColumnHelper<CentralWalletUser>();

export const nameColumn = columns.accessor(
  (data) => {
    return (
      <div
        style={{ fontSize: "14px", fontWeight: "normal" }}
        className="flex items-center"
      >
        <span
          style={{ fontSize: "14px", fontWeight: "bolder" }}
          className="font-body font-bold text-gray-600"
        >
          {data.username ?? ""}
          <br />
          <span
            style={{ fontSize: "12px", fontWeight: "normal" }}
            className="font-body  font-normal text-gray-400"
          >
            {data.email ?? ""}
          </span>
        </span>
      </div>
    );
  },
  {
    id: "user-name",
    header: tableHeader("Name", "User Email"),
    cell: (row) => row.getValue(),
  },
);

export const userIdColumn = columns.accessor(
  (data) => {
    return (
      <div
        style={{ fontSize: "14px", fontWeight: "normal" }}
        className="flex items-center"
      >
        <span
          style={{ fontSize: "14px", fontWeight: "normal" }}
          className="font-body font-medium text-gray-800"
        >
          {truncateString(`${data?.id}`, 10)} <br />
        </span>
        <div className="z-2 pl-2">
          <CopyToClipboard
            text={`${data?.id}`}
            onCopy={() => {
              notify("success", "User ID copied");
            }}
          >
            <button type="button" className={""}>
              <CopyIcon h="14" w="14" color="#4084B5" />
            </button>
          </CopyToClipboard>
        </div>
      </div>
    );
  },
  {
    id: "user-id",
    header: tableHeader("User ID"),
    cell: (row) => row.getValue(),
  },
);

export const walletBalanceColumn = columns.accessor(
  (data) => {
    const currency_symbol = useSharedCurrency()?.code;
    return (
      <div
        style={{ fontSize: "14px", fontWeight: "normal" }}
        className="flex items-center"
      >
        <span
          style={{ fontSize: "14px", fontWeight: "bolder" }}
          className="font-body font-bold text-gray-600"
        >
          {BalanceMap.format(currency_symbol, data.balances.wallet)}
          <br />
          <span
            style={{ fontSize: "12px", fontWeight: "normal" }}
            className="font-body  font-normal text-gray-400"
          >
            {currency_symbol}
          </span>
        </span>
      </div>
    );
  },
  {
    id: "wallet-balance",
    header: tableHeader("Wallet Balance", "Currency"),
    cell: (row) => row.getValue(),
  },
);

export const vaultBalanceColumn = columns.accessor(
  (data) => {
    const currency_symbol = useSharedCurrency()?.code;
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {BalanceMap.format(currency_symbol, data.balances.vault)} <br />
        <span
          style={{ fontSize: "13px", fontWeight: "normal" }}
          className="font-body  font-normal text-gray-400"
        >
          {currency_symbol}
        </span>
      </span>
    );
  },
  {
    id: "vault-balance",
    header: tableHeader("Vault Balance", "Currency"),
    cell: (row) => row.getValue(),
  },
);

export const totalBalanceColumn = columns.accessor(
  (data) => {
    const currency_symbol = useSharedCurrency()?.code;
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {BalanceMap.format(currency_symbol, data.balances.total)}
        <br />
        <span
          style={{ fontSize: "13px", fontWeight: "normal" }}
          className="font-body  font-normal text-gray-400"
        >
          {currency_symbol}
        </span>
      </span>
    );
  },
  {
    id: "total-balance",
    header: tableHeader("Total Balance", "Currency"),
    cell: (row) => row.getValue(),
  },
);

export const createdAtColumn = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {getDateFormat(data.created_at).dateFormat}
        <br />
        <span
          style={{ fontSize: "13px", fontWeight: "normal" }}
          className="font-body  font-normal text-gray-400"
        >
          {getDateFormat(data.last_active).dateFormat}
        </span>
      </span>
    );
  },
  {
    id: "date-time",
    header: tableHeader("Date Created", "Last Active"),
    cell: (row) => row.getValue(),
  },
);

export const statusColumn = columns.accessor(
  (data) => {
    return (
      <>
        <Badge type={userBadgeStatus(data?.status)} showIcon={false}>
          {capitalize(toLower(data?.status))}
        </Badge>
      </>
    );
  },
  {
    id: "status",
    header: tableHeader("Status"),
    cell: (row) => row.getValue(),
  },
);

export const actionColumn = columns.accessor(
  (data) => {
    return (
      <LinkDropdown
        handleEdit={() => {
          console.log(data);
        }}
      />
    );
  },
  {
    id: "cat",
    header: tableHeader(""),
    cell: (row) => row.getValue(),
  },
);

function tableHeader(
  mainHeading: string,
  subHeading?: string,
): () => React.JSX.Element {
  return () => {
    return (
      <div className="flex flex-col">
        <span
          className={`text-sm font-bold leading-tight text-gray-600 ${
            !subHeading ? "py-5" : "pt-2"
          }`}
        >
          {mainHeading}
        </span>
        {!subHeading ? null : (
          <span
            style={{
              fontSize: "12px",
              fontWeight: "normal",
              marginTop: "2px",
            }}
            className="font-body font-normal text-gray-400"
          >
            {subHeading}
          </span>
        )}
      </div>
    );
  };
}

export function CTA({
  copyText,
  onClick,
}: {
  copyText: string;
  onClick: () => void;
}) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="px-5">
        <div className="flex cursor-pointer items-center text-sm text-[#909090]">
          <div className="z-2 pl-2">
            <CopyToClipboard
              text={`${copyText}`}
              onCopy={() => {
                onClick();
              }}
            >
              <button type="button" className={""}>
                <CopyIcon h="14" w="14" color="#4084B5" />
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </DropdownMenuTrigger>
    </DropdownMenu>
  );
}
