import React from "react";
import { RecipientCtx } from ".";
import { Button } from "~/@/components/ui/button";
import { Input } from "~/@/components/ui/input";
import * as Address from "~/components/Inputs/CountryAddressFields";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "~/@/components//ui/form";
import { CardSeparator } from "~/components/layouts/Card";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { CurrencyDropdown } from "~/components/Inputs";
import { useCountries } from "~/hooks";
import { CountryI } from "~/general/interfaces";
import {
  useAddLinkedAccountBankNgn,
  useGetLinkedAccounts,
} from "~/api/codegen/liquidityComponents";
import { useGetBusinessId } from "~/hooks/use-business";
import { LinkedAccountType } from "../StepRecipient";
import { ChoiceType } from "~/libs/types.helper";
import { notify } from "~/components/Toast";
import { da } from "date-fns/locale";
import { PayoutCtx } from "..";

const schema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  nickName: z.string(),
  email: z.string(),
  selectedCountry: z.string(),
  selectedCurrency: z.string(),
  state: z.string(),
  city: z.string(),
  streetAddress: z.string(),
  zipCode: z.string(),
});

export interface RecipientDetailsType {
  firstName: string;
  lastName: string;
  nickName: string;
  email: string;
  streetAddress: string;
  selectedCountry: string;
  state: string;
  city: string;
  zipCode: string;
  selectedCurrency: string;
}

const RecipientDetails = ({ currentMove, setCurrentMove }) => {
  const { recipientState, updateState } = useContext(RecipientCtx);
  const { payoutState } = useContext(PayoutCtx);
  const form = useForm<z.infer<typeof schema>>();

  const mutation = useAddLinkedAccountBankNgn();

  useEffect(() => {
    form.setValue("selectedCountry", payoutState?.country?.iso2);
    form.setValue("selectedCurrency", payoutState?.country?.currency);
  }, [payoutState?.country]);

  return (
    <div className=" mx-auto w-full max-w-[613px] rounded-lg bg-[white] px-10">
      <div className="text-left">
        <h2 className="pt-5 text-xl font-normal text-muted-foreground">
          Enter Recipient Details
        </h2>
      </div>
      <form
        className="mt-[20px] flex flex-col text-left"
        onSubmit={form.handleSubmit(async (data) => {
          try {
            updateState("recipientDetails", data);

            console.log("Recipient Details", data);
            setCurrentMove(currentMove + 1);
          } catch (err) {
            console.error(err);
            notify("error", `${err.message}`);
          }
        })}
      >
        <Form {...form}>
          <div className="">
            <h2 className="pt-2 text-base font-normal text-gray-400">
              Recipient Details
            </h2>
            <CardSeparator className="my-2 mb-5" />
            <div className="flex w-full items-center gap-2">
              <div className="w-full">
                <FormField
                  control={form.control}
                  name={"firstName"}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>First Name</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="" className="w-full" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
              <div className="w-full">
                <FormField
                  control={form.control}
                  name={"lastName"}
                  render={({ field }) => {
                    return (
                      <FormItem className="w-full">
                        <FormLabel>Last Name</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="" className="w-full" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
            </div>
            <div className="my-3" />
            <FormField
              control={form.control}
              name={"nickName"}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Nick Name (optional)</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="" className="w-full" />
                    </FormControl>
                    <FormMessage />
                    <FormDescription className="text-xs">
                      For your reference
                    </FormDescription>
                  </FormItem>
                );
              }}
            />
            <div className="my-3" />
            <FormField
              control={form.control}
              name={"email"}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Email (optional)</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="" className="w-full" />
                    </FormControl>
                    <FormMessage />
                    <FormDescription className="text-xs">
                      We'll notify them via email that the payment is on the
                      way.
                    </FormDescription>
                  </FormItem>
                );
              }}
            />
            <div>
              <h2 className="pt-8 text-base font-normal text-gray-400">
                Recipient Address
              </h2>
              <CardSeparator className="my-2 mb-5" />

              <Address.Root defaultValue={form.watch("selectedCountry")}>
                <FormField
                  control={form.control}
                  name={"streetAddress"}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Street Address</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="" className="w-full" />
                        </FormControl>
                        <FormMessage />
                        <FormDescription className="text-xs" />
                      </FormItem>
                    );
                  }}
                />
                <div className="my-3" />
                <FormField
                  control={form.control}
                  name={"selectedCountry"}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Resident Country</FormLabel>
                        <FormControl>
                          <Address.CountryField
                            {...field}
                            // value={`${recipientState?.country?.iso2}`}
                          />
                        </FormControl>
                        <FormMessage />
                        <FormDescription className="text-xs" />
                      </FormItem>
                    );
                  }}
                />
                <div className="my-3" />
                <div className="flex w-full items-center gap-2">
                  <div className="w-full">
                    <FormField
                      control={form.control}
                      name={"state"}
                      render={({ field }) => {
                        return (
                          <FormItem className="w-full">
                            <FormLabel>State</FormLabel>
                            <FormControl>
                              <Address.StateField {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <FormField
                      control={form.control}
                      name={"city"}
                      render={({ field }) => {
                        return (
                          <FormItem className="w-full">
                            <FormLabel>City</FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                placeholder=""
                                className="w-full"
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <FormField
                      control={form.control}
                      name={"zipCode"}
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel>Zip Code</FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                value={field?.value?.replace(/[^0-9]/g, "")}
                                placeholder=""
                                className="w-full"
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </div>
                </div>
              </Address.Root>
            </div>
          </div>

          <div className="mt-5 items-center justify-between text-right">
            {/* <Button
              size="lg"
              variant="outline"
              className="mt-5 w-[15%]"
              onClick={() => {
                setCurrentMove(currentMove - 1);
              }}
            >
              Back
            </Button> */}
            <Button
              size="lg"
              variant="default"
              type="submit"
              className="mt-5 w-[15%]"
              // disabled={!form.formState.isDirty}
            >
              {/* <LoadingButtonContent loading={false}>Next</LoadingButtonContent>
               */}
              <LoadingButtonContent loading={mutation.isPending}>
                Next
              </LoadingButtonContent>
            </Button>
          </div>
        </Form>
      </form>
    </div>
  );
};

export default React.memo(RecipientDetails);
